@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700&display=swap');

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif !important;
  /* scrollbar-width: auto;
  scrollbar-color: var(--primary) var(--white); */
}

/* :root {
  --primary: #0B7770;
  --secondary: #292929;
  --heading: #032D23;
  --para: #4e4e4e;
  --gray: #797D8C;
  --black: #000;
  --white: #fff;
  --cardBG: #F6F8F9;
}

@font-face {
  font-family: "Gilroy-Medium";
  font-weight: 500;
  src: url("/src/assets/fonts/Gilroy-Medium.ttf");
} */

a {
  color: black !important;
  text-decoration: none;
}

/*--------------------- 
        Scrollbar css 
----------------------------------*/
*::-webkit-scrollbar {
  width: 9px;
  display: none;
}

*::-webkit-scrollbar-track {
  background: transparent;
}

*::-webkit-scrollbar-thumb {
  background-color: var(--primary);
  border-radius: 10px;
  border: 2px solid var(--white);
}

/*--------------------- 
        Scrollbar css 
----------------------------------*/

.active_sidebar:hover {
  background: #FFF !important;
}

.p_relative {
  position: relative;
}

.eye_btn button {
  position: absolute !important;
  right: 17px !important;
  top: 3px !important;
}

.alert_chart_select .MuiSelect-select {
  padding: 7px 15px !important;
}

/*--------------------- 
        DateRange css 
----------------------------------*/

.date_range button {
  background: transparent;
  color: #000;
  font-weight: 400;
  border: 1.5px solid black !important;
  border-radius: 4px;
  padding: 10px;
  text-align: start;
  font-size: 15px;
  width: 100%;
  box-shadow: 2px 3px 10px 0 rgba(0, 0, 0, .15);
}

.date_range_width button {
  width: unset !important;
  background: #000 !important;
  color: #fff !important;
}

.daterangepicker .ranges li.active {
  background-color: #000 !important;
}

.daterangepicker.opensright:before,
.daterangepicker.opensright:after {
  right: 20px !important;
  left: unset !important;
  display: none !important;
}

.show-calendar {
  right: 20px !important;
  overflow: scroll;
  height: 303px;
}

@media (max-width:563px) {
  .daterangepicker {
    right: 20px !important;
  }
}

.daterangepicker td.active,
.daterangepicker td.active:hover {
  background-color: #000 !important;
}

.applyBtn {
  background: #000 !important;
  border: 1px solid #000 !important;
}

.daterangepicker td.in-range {
  background-color: #eeeeee !important;
}

/*--------------------- 
        DateRange css 
----------------------------------*/